import { useQuery } from "@apollo/client";
import ClassPlayer from "app/components/ClassPlayer";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { SignUpModal } from "app/components/SignUpModal";
import { IS_MOBILE, IS_MOBILE_ONLY, PARTY_STATUSES } from "constants/index";
import { GET_PROGRAM_V2_CORE, GET_PROGRAM_V2_WITH_REF } from "graphql/queries";
import env from "helpers/env";
import useGetClass from "hooks/useGetClass";
import { joinPartyAction, setSeenPartyEndedModalAction } from "modules/party";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import isClient from "helpers/isClient";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import {
  DesktopClassDetails,
  MobileClassDetails,
} from "app/components/ClassComponents/ClassDetails";
import { MobileClassPreviewModal } from "app/components/ClassComponents/ClassPreviewModal";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { MobilePreviewPlayerModal } from "app/components/ClassComponents/MobilePreviewPlayer";
import { useCanUserTakeClass } from "hooks/Classes";
import { Routes } from "constants/routes";
import { SubscribeModal } from "app/components/SubscribeModal";
import {
  BrowserContainer,
  BrowserWrapper,
} from "app/components/ClassComponents/shared-styles";
import { PlayCircle } from "app/components/Icon";
import { H2 } from "app/components/Typography";
import Button from "app/components/Button";
import { useUserSubscription } from "modules/selectors";
import { ClassThumbnail } from "./ClassThumbnail";
import PartyModal from "../Party/PartyModal";
import { useGetNextClass } from "./hooks";

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

const AccessType = styled.span`
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.gold};
`;

function Class() {
  const location = useLocation();
  const history = useHistory();
  const { isSubscriptionActive } = useUserSubscription();
  const { id: classId } = useParams() as { id: string };
  const partyState = useSelector(({ user }: any) => user.private);
  const partyData = partyState?.party;
  const hasSeenPartyEndedModal = Boolean(partyData?.seenEndedModalAt);
  const dispatch = useDispatch();
  const { isFamilyModeOn } = useFamilyModeSetting();
  const { canUserTakeClass, refetchCanUserTakeClass } = useCanUserTakeClass({
    classId,
  });
  const { classRefId, program: programSlugQuery } = queryString.parse(
    location.search
  ) as { classRefId: string; program: string };
  const { nextClassData } = useGetNextClass();
  const [
    isMobileClassPreviewModalOpen,
    setIsMobileClassPreviewModalOpen,
  ] = useState(false);
  const isAnonymous = useSelector(({ auth }: any) => auth.isAnonymous);
  const [isPartyEndedModalOpen, setIsPartyEndedModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(isAnonymous);
  const [isSubscribeModalShowing, setIsSubscribeModalShowing] = useState(false);
  const [isCompletionOverlayShowing, setIsCompletionOverlayShowing] = useState(
    false
  );
  const [isClassOverlayTimerStopped, setIsClassOverlayTimerStopped] = useState(
    false
  );
  const [
    isMobilePreviewPlayerModalOpen,
    setIsMobilePreviewPlayerModalOpen,
  ] = useState(false);

  const [getClassData, isClassLoading] = useGetClass({
    variables: {
      classId,
      programClassRefId: classRefId,
    },
    notFoundRedirectPath: "/dashboard",
  });
  const classData = getClassData?.current || {};

  const classVideo = {
    sections: classData.sections?.map((s: any) => ({
      name: s.name,
      cuepoints: s.cuepoints.map((c: any) => ({
        name: c.name,
        time: c.time,
        duration_in_seconds: c.timeSec,
      })),
    })),
  };

  const { data: { getProgramV2WithRef } = {} } = useQuery(
    GET_PROGRAM_V2_WITH_REF,
    {
      variables: { classRefId },
      skip: classRefId == null,
    }
  );
  const { data: { getProgramV2 } = {} } = useQuery(GET_PROGRAM_V2_CORE, {
    variables: { slug: programSlugQuery },
    skip: programSlugQuery == null,
  });
  const programData = getProgramV2WithRef || getProgramV2;

  const isPartyClass = parseInt(partyData?.classId) === parseInt(classData.id);
  const partyStatus = isPartyClass ? partyData?.status : null;

  const classDetailsRef = useRef(null);
  const [scroll, setScroll] = useState(false);

  const scrollToClassDetails = () => {
    setScroll(true);
  };

  useEffect(() => {
    if (scroll) {
      if (classDetailsRef?.current) {
        classDetailsRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
      setScroll(false);
    }
  }, [scroll]);

  useEffect(() => {
    if (!partyState.started && partyStatus === "APPROVED") {
      /**
       * @CALLOUT
       * This block currently runs twice after ending a party.
       *
       * Something causes `partyState.started` to toggle between:
       * false --> true --> false --> true --> false,
       * which likely causes components to react inadvertently.
       *
       * This is not a huge deal, but should be kept in mind when dealing with
       * components that set up subscriptions (e.g. the party call)
       * when extending or debugging features.
       *
       * Trying to clean this up right now may have ripple effects,
       * but this should be cleaned up if this ever gets refactored.
       */
      dispatch(joinPartyAction());
    }
  }, [isPartyClass, partyState.started, partyData, dispatch]);

  useEffect(() => {
    if (isClassLoading) {
      return;
    }

    if (partyStatus === PARTY_STATUSES.APPROVED) {
      return;
    }

    const isPartyExpired = partyData?.expiresAt < new Date().toISOString();

    if (partyStatus === PARTY_STATUSES.ENDED && !isPartyExpired) {
      if (!canUserTakeClass) {
        history.push(`/party?pid=${partyData?.id}`);
        return;
      }

      if (!hasSeenPartyEndedModal && !isPartyEndedModalOpen) {
        setIsPartyEndedModalOpen(true);
      }
    }

    if (isAnonymous && classData.isFree) {
      setIsSignUpModalOpen(true);
      return;
    }

    if (!canUserTakeClass) {
      setIsSubscribeModalShowing(true);
      return;
    }

    setIsSubscribeModalShowing(false);
  }, [canUserTakeClass, classData.isFree, partyStatus]);

  if (isClassLoading) {
    return <LoaderCentered />;
  }

  if (isClient && isAnonymous && classData.isFree === false) {
    /*
      Want to grab search params that can be forwarded from external pages
      We forward these params to register page for proper event attribution
    */
    const searchObj = {
      ...queryString.parse(location.search),
      redirect: location.pathname,
    };

    return (
      <Redirect
        to={{
          pathname: "/register",
          search: `?${queryString.stringify(searchObj)}`,
        }}
      />
    );
  }

  if (classData.publish_date && moment().isBefore(classData.publish_date)) {
    return <Redirect to="/library/classes" />;
  }

  if (isFamilyModeOn && !classData.isFamilyFriendly) {
    return <Redirect to="/library/classes" />;
  }
  const closeModal = () => {
    refetchCanUserTakeClass();
    setIsSubscribeModalShowing(false);
  };

  const isClassPlayable = !IS_MOBILE && canUserTakeClass;

  return (
    <Wrapper>
      <Helmet
        title={`${classData.title} | Online Dance Classes and Tutorials`}
      />
      <meta
        property="og:url"
        content={`https://${env("PUBLIC_DOMAIN")}/class/${classData.id}`}
      />
      <meta property="og:title" content={`${classData.title}`} />
      <meta
        property="og:description"
        content={`Take this class with ${classData.instructor?.name} and become a better dancer!`}
      />
      <meta
        property="og:image"
        content={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
          classData.thumbnail
        }-basic.jpg?w=640`}
      />

      <meta
        property="twitter:url"
        content={`https://${env("PUBLIC_DOMAIN")}/class/${classData.id}`}
      />
      <meta property="twitter:title" content={`${classData.title}`} />
      <meta
        property="twitter:description"
        content={`Take this class with ${classData.instructor?.name} and become a better dancer!`}
      />
      <meta
        property="twitter:image"
        content={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
          classData.thumbnail
        }-basic.jpg?w=640`}
      />
      {!IS_MOBILE_ONLY && (
        <SignUpModal
          isOpen={isAnonymous && isSignUpModalOpen}
          routeToRedirectAfterClose={Routes.library__classes}
          eventAttributes={{
            class_id: parseInt(classData.id),
            module: "Class Player",
          }}
          closeModal={() => {
            return setIsSignUpModalOpen(!isSignUpModalOpen);
          }}
        />
      )}
      {isClassPlayable ? (
        <ClassPlayer
          classData={classData}
          classVideo={classVideo}
          programData={programData}
          nextClassData={nextClassData}
          isSubscribeModalShowing={isSubscribeModalShowing}
          setIsSubscribeModalShowing={setIsSubscribeModalShowing}
          showOverlay={isCompletionOverlayShowing}
          setShowOverlay={setIsCompletionOverlayShowing}
          isClassOverlayTimerStopped={isClassOverlayTimerStopped}
          setIsClassOverlayTimerStopped={setIsClassOverlayTimerStopped}
          scrollToClassDetails={scrollToClassDetails}
          setIsMobileClassPreviewModalOpen={setIsMobileClassPreviewModalOpen}
        />
      ) : (
        <BrowserWrapper>
          <BrowserContainer>
            <Flex flexDirection="column" width="100%" p="12px" gap="8px">
              {!canUserTakeClass && (
                <Flex
                  p="12px"
                  borderRadius="8px"
                  bg="black"
                  color="white"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="12px"
                  flexWrap="wrap"
                >
                  <H2>
                    This class requires a{" "}
                    <AccessType>{classData.accessType}</AccessType> subscription
                    plan.{" "}
                  </H2>
                  <Button
                    width={{ _: "100%", md: "auto" }}
                    variant="primary"
                    onClick={() => setIsSubscribeModalShowing(true)}
                  >
                    {isSubscriptionActive ? "Upgrade Now" : "Subscribe Now"}
                  </Button>
                </Flex>
              )}
              <Div
                pt="56.25%"
                position="relative"
                borderRadius="8px"
                overflow="hidden"
                onClick={() => setIsMobilePreviewPlayerModalOpen(true)}
                cursor="pointer"
              >
                <Div
                  width="100%"
                  height="100%"
                  position="absolute"
                  top={0}
                  left={0}
                >
                  <ClassThumbnail classData={classData} />
                  <Flex
                    position="absolute"
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    top="0"
                    background="rgba(0, 0, 0, 0.5)"
                  >
                    <PlayCircle width="50px" height="50px" color="white" />
                  </Flex>
                </Div>
              </Div>
            </Flex>
          </BrowserContainer>
        </BrowserWrapper>
      )}
      <PartyModal
        classData={classData}
        isOpen={isPartyEndedModalOpen}
        afterOpen={() => dispatch(setSeenPartyEndedModalAction())}
        toggleModal={() => setIsPartyEndedModalOpen(false)}
        content={{
          headerTitle: "Party has ended", // eslint-disable-next-line no-multi-str
          body:
            "This party has ended! Pick a new class to continue dancing. Hope you enjoyed grooving with your friends!",
        }}
      />
      <SubscribeModal
        isOpen={isSubscribeModalShowing}
        classData={classData}
        closeModal={closeModal}
      />
      <div ref={classDetailsRef} style={{ scrollMarginTop: "60px" }}>
        {IS_MOBILE ? (
          <MobileClassDetails
            classRefId={classRefId}
            classData={classData}
            setIsMobileClassPreviewModalOpen={setIsMobileClassPreviewModalOpen}
            setIsMobilePreviewPlayerModalOpen={
              setIsMobilePreviewPlayerModalOpen
            }
          />
        ) : (
          <DesktopClassDetails
            ref={classDetailsRef}
            classData={classData}
            classRefId={classRefId}
          />
        )}
      </div>
      {isMobileClassPreviewModalOpen && (
        <MobileClassPreviewModal
          classData={classData}
          closeModal={() => {
            setIsMobileClassPreviewModalOpen(false);
          }}
        />
      )}
      <MobilePreviewPlayerModal
        isOpen={isMobilePreviewPlayerModalOpen}
        classData={classData}
        closeModal={() => setIsMobilePreviewPlayerModalOpen(false)}
      />
    </Wrapper>
  );
}

export default Class;
